@font-face {
  font-family: 'Chiswick';
  src: url('../public/fonts/chiswick/ChiswickSansText-Regular-Web.woff2')
    format('woff2');
  font-display: block;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Chiswick';
  src: url('../public/fonts/chiswick/ChiswickSansText-Semibold-Web.woff2')
    format('woff2');
  font-display: block;
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Blanco';
  src: url('../public/fonts/blanco/BlancoSubset-Regular.woff2') format('woff2');
  font-display: block;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Blanco';
  src: url('../public/fonts/blanco/BlancoSubset-Italic.woff2') format('woff2');
  font-display: block;
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Blanco';
  src: url('../public/fonts/blanco/BlancoSubset-Bold.woff2') format('woff2');
  font-display: block;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Blanco';
  src: url('../public/fonts/blanco/BlancoSubset-BoldItalic.woff2')
    format('woff2');
  font-display: block;
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@layer components {
  /* EyeBrows */
  .fora-text-eyebrow-1 {
    @apply font-sans text-base font-normal leading-none uppercase tracking-6 md:text-18;
  }
  .fora-text-eyebrow-2 {
    @apply font-sans font-normal leading-none uppercase text-14 md:text-base tracking-8;
  }
  .fora-text-eyebrow-3 {
    @apply font-sans font-normal leading-none uppercase tracking-10 text-12 md:text-14;
  }
  .fora-text-eyebrow-4 {
    @apply font-sans font-normal uppercase tracking-12 leading-150 text-10 md:leading-none md:text-12;
  }

  /* Headlines */
  .fora-text-h1 {
    @apply font-sans font-normal leading-none tracking-tight md:leading-90 text-60 md:text-84;
  }
  .fora-text-h2 {
    @apply font-sans font-normal tracking-tight leading-105 md:leading-95 text-48 md:text-72;
  }
  .fora-text-h3 {
    @apply font-sans font-normal tracking-tight leading-105 md:leading-none text-32 md:text-60;
  }
  .fora-text-h4 {
    @apply font-sans font-normal tracking-tight leading-105 md:leading-none text-24 md:text-48;
  }
  .fora-text-h5 {
    @apply font-sans font-normal tracking-tight leading-115 md:leading-105 text-20 md:text-36;
  }
  .fora-text-h6 {
    @apply font-sans font-normal tracking-tight leading-115 md:leading-105 text-18 md:text-30;
  }
  .fora-text-h7 {
    @apply font-sans text-base font-normal tracking-tight leading-115 md:leading-110 md:text-24;
  }
  .fora-text-h8 {
    @apply font-sans font-normal tracking-tight leading-115 md:leading-110 text-14 md:text-18;
  }
  .fora-text-h9 {
    @apply font-sans font-normal tracking-2 leading-115 md:leading-110 text-12 md:text-14;
  }

  /* Subtitles */
  .fora-text-subtitle-1 {
    @apply font-serif text-base font-bold tracking-normal leading-150 md:leading-135 md:text-18;
  }
  .fora-text-subtitle-2 {
    @apply font-serif font-bold tracking-normal leading-130 md:leading-120 text-14 md:text-base;
  }
  .fora-text-subtitle-3 {
    @apply font-serif font-bold tracking-normal leading-120 md:leading-110 text-12 md:text-12;
  }

  /* Captions */
  .fora-text-caption-1 {
    @apply font-sans !text-base font-normal tracking-tight leading-110 md:leading-140;
  }
  .fora-text-caption-2 {
    @apply font-sans tracking-tight text-14 leading-110 md:leading-140;
  }
  .fora-text-caption-serif-1 {
    @apply font-serif text-base font-normal tracking-tight leading-140;
  }
  .fora-text-caption-serif-2 {
    @apply font-serif font-normal tracking-tight text-14 leading-140;
  }

  /* Pull Quotes */
  .fora-text-quote-1 {
    @apply font-sans font-semibold tracking-normal text-48 md:tracking-btight leading-110 md:text-72;
  }

  /* Navigation */
  .fora-text-navigation-1 {
    @apply font-sans font-semibold tracking-2 text-24 leading-140 md:text-14;
  }
  .fora-text-navigation-2 {
    @apply font-sans font-semibold tracking-2 text-20 leading-140 md:text-12;
  }
  .fora-text-navigation-3 {
    @apply font-sans text-base font-semibold tracking-2 leading-140 md:text-10;
  }
  .fora-text-navigation-4 {
    @apply font-sans font-semibold tracking-2 leading-140 text-12;
  }

  /* Body */
  .fora-text-body-1 {
    @apply font-serif text-base font-normal tracking-normal leading-140 md:leading-120 md:text-20 text-darkStone;
  }
  .fora-text-body-italic-1 {
    @apply font-serif text-base italic font-normal tracking-normal text-darkStone leading-140 md:leading-120 md:text-20;
  }
  .fora-text-body-link-1 {
    @apply font-serif text-base font-normal tracking-normal underline text-darkStone leading-140 md:leading-120 md:text-20;
  }
  .fora-text-body-2 {
    @apply font-serif font-normal tracking-normal text-darkStone text-14 leading-150 md:leading-135 md:text-18;
  }
  .fora-text-body-italic-2 {
    @apply font-serif italic font-normal tracking-normal text-darkStone leading-150 md:leading-135 text-14 md:text-18;
  }
  .fora-text-body-link-2 {
    @apply font-serif font-normal tracking-normal underline text-darkStone leading-150 md:leading-135 text-14 md:text-18;
  }
  .fora-text-body-3 {
    @apply font-serif font-normal tracking-normal text-darkStone text-12 leading-150 md:leading-140 md:text-base;
  }
  .fora-text-body-italic-3 {
    @apply font-serif italic font-normal tracking-normal text-darkStone leading-150 md:leading-140 text-12 md:text-base;
  }
  .fora-text-body-link-3 {
    @apply font-serif font-normal tracking-normal text-darkStone leading-150 md:leading-140 text-12 md:text-base;
  }

  /* Tabs */
  .fora-text-tab-1 {
    @apply font-sans font-semibold uppercase tracking-2 leading-140 text-24;
  }

  /* Button */
  .fora-text-button-1 {
    @apply font-sans font-semibold leading-none tracking-2 text-14;
  }
  .fora-text-button-2 {
    @apply font-sans font-semibold leading-none tracking-2 text-12;
  }

  /* Callouts */
  .fora-text-callout-1 {
    @apply font-serif italic font-normal leading-none tracking-tight md:leading-90 text-60 md:text-84;
  }
  .fora-text-callout-2 {
    @apply font-serif italic font-normal tracking-tight leading-105 md:leading-95 text-48 md:text-72;
  }
  .fora-text-callout-3 {
    @apply font-serif italic font-normal tracking-tight leading-105 md:leading-none text-32 md:text-60;
  }
  .fora-text-callout-4 {
    @apply font-serif italic font-normal tracking-tight leading-105 md:leading-none text-24 md:text-48;
  }
  .fora-text-callout-5 {
    @apply font-serif italic font-normal tracking-tight leading-115 md:leading-105 text-20 md:text-36;
  }
  .fora-text-callout-6 {
    @apply font-serif italic font-normal tracking-tight leading-115 md:leading-105 text-18 md:text-30;
  }
  .fora-text-callout-7 {
    @apply font-serif text-base italic font-normal tracking-tight leading-115 md:leading-110 md:text-24;
  }
  .fora-text-callout-8 {
    @apply font-serif italic font-normal tracking-tight leading-115 md:leading-110 text-14 md:text-18;
  }
  .fora-text-callout-9 {
    @apply font-serif italic font-normal tracking-2 leading-115 md:leading-110 text-12 md:text-14;
  }

  .dropdown-link:hover {
    text-underline-offset: 12px;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
  }

  /* Shared block-level responsive margins and padding */

  .__fora-shared-block-element {
    @apply pb-12 border-b md:pb-12 border-darkShell;
  }

  /* Input placeholder */
  input[type='text']::-webkit-input-placeholder {
    @apply text-base font-normal text-darkStone;
  }

  input[type='radio'] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #7a7774;
    border-radius: 50%;
  }

  input[type='radio']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }

  input[type='radio']:checked {
    border-color: #131313;
  }

  input[type='radio']:checked:before {
    background: #131313;
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #7a7774;
    border-radius: 2px;
    position: relative;
  }

  input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    top: -2px;
    left: -2px;
  }

  input[type='checkbox']:checked {
    border-color: #131313;
  }

  input[type='checkbox']:checked:before {
    background-image: url('https://media.foratravel.com/image/upload/v1715877414/icon-filled-check_arzw9q.svg');
    background-size: cover;
    background-repeat: no-repeat;
  }

  /* Advisor profile card title */
  .advisor-profile-card-h1 {
    @apply font-sans font-medium md:tracking-btight text-sans-h3;
  }

  /* Lear more block title */
  .learn-more-block .global-richtext h1,
  .learn-more-block .global-richtext h5 {
    @apply mb-2 uppercase fora-text-eyebrow-1;
  }
  .learn-more-block .global-richtext h4 {
    @apply font-serif text-base tracking-normal text-darkStone md:text-xl;
  }
  .learn-more-block .global-richtext p {
    @apply fora-text-body-1 text-darkStone;
  }

  /*Forms message error*/
  .error-message {
    @apply text-sm uppercase text-red;
  }
}

.global-richtext h1 {
  @apply fora-text-h1;
}

.global-richtext h2 {
  @apply fora-text-h2;
}

.global-richtext h3 {
  @apply fora-text-h3;
}

.global-richtext h4 {
  @apply fora-text-h4;
}

.global-richtext h5 {
  @apply fora-text-h5;
}

.global-richtext h6 {
  @apply fora-text-h6;
}

.global-richtext a,
.global-richtext a:active,
.global-richtext a:visited,
.global-richtext a:link {
  @apply underline text-blackSand;
}

.global-richtext a:hover {
  @apply no-underline transition-all text-darkStone;
}

.global-richtext p,
.global-richtext ol {
  @apply text-darkStone fora-text-body-1;
}

.global-richtext p {
  @apply mb-6;
}

.global-richtext hr {
  @apply mb-12 border-0;
}

.global-richtext ol li p:last-child,
.global-richtext ul li p:last-child,
.global-richtext p p {
  @apply pb-2;
}

.global-richtext ol li:last-of-type p,
.global-richtext ul li:last-of-type p {
  @apply !pb-0;
}

.global-richtext p:empty {
  display: none;
}

.global-richtext ol {
  @apply ml-5 list-decimal;
}

.global-richtext ul {
  @apply ml-5 list-disc;
}

.global-richtext blockquote {
  @apply p-4 pl-8 mb-4 bg-white border-l-4 border-shell text-darkStone;
}

.page-blocks-page-content-emphasized .global-richtext p {
  @apply mb-6 fora-text-body-italic-1 md:mb-4;
}

.legal-page-content .global-richtext h1,
.legal-page-content .global-richtext h2,
.legal-page-content .global-richtext h3,
.legal-page-content .global-richtext h4,
.legal-page-content .global-richtext h5,
.legal-page-content .global-richtext h6 {
  @apply mb-6 uppercase text-blackSand fora-text-h7;
}

.legal-page-content .global-richtext hr {
  @apply mb-2 md:mb-10;
}

.legal-page-content .global-richtext ul {
  @apply mb-6 md:mb-10;
}

.legal-page-content .global-richtext td a {
  @apply text-red;
}

/* Remove bottom margin from last elements */
.global-richtext p:last-child,
.global-richtext ul:last-child,
.global-richtext ol:last-child,
.global-richtext blockquote p:last-child {
  @apply m-0;
}

.itinerary-detail-richtext .global-richtext h1,
.itinerary-detail-richtext .global-richtext h2,
.itinerary-detail-richtext .global-richtext h3,
.itinerary-detail-richtext .global-richtext h4,
.itinerary-detail-richtext .global-richtext h5,
.itinerary-detail-richtext .global-richtext h6 {
  @apply mb-8 capitalize-first text-blackSand md:fora-text-h7 fora-text-h5;
}

.itinerary-detail-richtext .global-richtext p {
  @apply mb-6 md:mb-8 text-darkStone;
}

.itinerary-detail-richtext .global-richtext ul {
  list-style-type: none;
  margin-left: 0;
  margin-bottom: 32px;
}

.itinerary-detail-richtext .global-richtext ul li:not(.list-none) {
  background-image: url('https://media.foratravel.com/image/upload/v1717155996/arrow-right_q1paaf.svg');
  background-position: 0px 5px;
  background-size: 0.875rem 0.875rem;
  background-repeat: no-repeat;
  padding-left: 22px;
}

.itinerary-detail-richtext .global-richtext ul li p {
  @apply !pb-4 mb-0;
}

.hotel-description-richtext .global-richtext p {
  @apply fora-text-body-2 text-darkStone;
}

.callout-copy .global-richtext h3 {
  @apply pb-4 md:pb-8 fora-text-h2;
}

.callout-copy .global-richtext p {
  @apply fora-text-body-1;
}

.text-underneath-button .global-richtext p {
  @apply fora-text-callout-6 md:fora-text-callout-7;
}

.text-underneath-button .global-richtext a:link {
  @apply text-darkStone;
}

.dialog-box {
  @apply absolute flex items-center justify-center px-6 py-3 text-sm uppercase md:text-base -right-6 md:right-8 top-14 md:top-16 bg-sand;
  opacity: 0;
  transition: all 0.5s ease-in;
}

.dialog-box.show {
  opacity: 0.99;
}

.dialog-box::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  bottom: 100%;
  left: 66px;
  border: 12px solid transparent;
  border-top: none;
  border-bottom-color: #fbf5ee;
}

.blog-content-richtext .global-richtext h1,
.blog-content-richtext .global-richtext h2,
.blog-content-richtext .global-richtext h3,
.blog-content-richtext .global-richtext h4,
.blog-content-richtext .global-richtext h5,
.blog-content-richtext .global-richtext h6 {
  @apply pb-6 mb-0 lg:pb-8 fora-text-h4 md:fora-text-h5;
}
.blog-content-richtext .global-richtext > p {
  @apply fora-text-body-1;
}

.blog-content-richtext .global-richtext ul {
  @apply pb-0;
}

.global-richtext ol li p:last-child,
.global-richtext ul li p:last-child,
.global-richtext p p {
  @apply pb-6;
}

.blog-content-richtext .global-richtext ol li p:last-child,
.blog-content-richtext .global-richtext ul li p:last-child {
  @apply pb-2;
}

.destination-perks div {
  width: 282px;
}

.destination-perks h5 {
  @apply mb-3 uppercase md:fora-text-eyebrow-1 fora-text-eyebrow-2 md:mb-2;
}

@media screen and (min-width: 821px) {
  .destination-hero .global-richtext p {
    width: 384px;
  }
  .destination-hero-wrapper {
    overflow: hidden;
  }
}

@media (max-width: 821px) {
  .destination-hero span img {
    transform: translate(calc(-600px + 100vw / 2), 0);
  }

  .destination-hero-wrapper {
    width: 100%;
    overflow: hidden;
  }
}

.campaign-page .global-richtext h2 {
  @apply fora-text-h2;
}

.campaign-page .global-richtext p {
  @apply tracking-normal;
}

.campaign-page .global-richtext li p {
  @apply text-left;
}

.partner-card-richtext .global-richtext {
  @apply fora-text-body-1;
}

.learn-more-teaser-copy .global-richtext p {
  @apply fora-text-body-1;
}

.related-destination-box div:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 10;
  opacity: 0.25;
}

.global-richtext .featured-advisors .header-block h1 {
  @apply fora-text-h2;
}

.global-richtext .featured-advisors .header-block h2 {
  @apply fora-text-h3;
}

.global-richtext .featured-hotels .header-block h1 {
  @apply fora-text-h2;
}

.global-richtext .featured-hotels .header-block h2 {
  @apply fora-text-h3;
}

.global-richtext .featured-advisors .advisor-card a {
  @apply no-underline;
}

.global-richtext .featured-advisors .advisor-card h2 {
  @apply fora-text-subtitle-1;
}

.global-richtext .featured-advisors .advisor-card h3 {
  @apply fora-text-h5;
}

.global-richtext .featured-advisors .advisor-card h4 {
  @apply fora-text-subtitle-1;
}

.global-richtext .featured-hotels .hotel-card h2 {
  @apply fora-text-h6;
}

.global-richtext .featured-hotels .hotel-card h3 {
  @apply fora-text-subtitle-2;
}

.global-richtext .featured-hotels .hotel-card ul li {
  list-style-type: none;
  margin-left: 0;
}

.global-richtext .input-message {
  @apply fora-text-h8;
}

.input-message {
  text-wrap-mode: nowrap;
}

.dropdown {
  display: none;
  position: absolute;
  opacity: 0;
  height: 0;
  left: 20px;
}

.dropdown.show {
  animation: ani-small 0.2s ease forwards;
  opacity: 1;
  display: block;
}

.dropdown.override-height {
  height: fit-content !important;
}

.dropdown.show.w-banner {
  top: 117px;
  width: 100%;
  right: 0;
}

.dropdown a span {
  text-wrap: nowrap;
}

.dropdown-t {
  position: absolute;
  width: 100%;
  @apply bg-sand;
  opacity: 0;
  height: 0;
  display: none;
  left: 20px;
  z-index: 100;
}

.dropdown-t.show {
  pointer-events: all;
  height: auto;
  max-height: 320px;
  display: block;
}

@keyframes ani-small {
  0% {
    height: 0;
    opacity: 0;
    top: 80px;
  }
  100% {
    height: 195px;
    opacity: 1;
    top: 80px;
  }
}

.menu-items span:hover,
.menu-items:focus span,
.menu-items a:focus span,
.menu-items.active span {
  text-decoration: underline;
  @apply text-blackSand;
  text-underline-offset: 4px;
}

.menu-items span {
  display: inline-flex;
  flex-direction: column;
}

.menu-items span::before {
  content: attr(data-label);
  height: 0;
  visibility: hidden;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  font-weight: 700;
}

@media (max-width: 1024px) {
  .dropdown {
    display: none;
    position: relative;
    height: unset;
    top: 0;
    opacity: unset;
  }
  .dropdown.show {
    opacity: unset;
    animation: none;
    display: block;
  }
  .dropdown-mobile {
    position: absolute;
    top: 0;
    z-index: 100000;
    width: 100vw;
    height: 100vh;
    left: 0;
    @apply !bg-sand;
  }
  .dropdown-mobile.show li:first-of-type:before {
    content: '';
    position: absolute;
    width: calc(100% - 20px);
    height: 1px;
    bottom: 0;
    @apply bg-darkShell;
    left: 50%;
    transform: translateX(-50%);
  }
}
.nav-btn div {
  height: auto !important;
}
.nav-btn div {
  height: auto !important;
}

.searchInput {
  width: 18px;
  right: -28px;
  padding-right: 0px;
  height: 24px;
  border: 0;
}

.searchInput input {
  padding-left: 0;
  width: 0px;
  height: 100%;
}
.searchInput.showSearch input {
  animation: input 0.45s 0.3s linear forwards;
}

.searchInput.showSearch {
  animation: searchKey 0.5s ease forwards;
  margin-right: 0;
}

.searchInput input:focus {
  outline: none;
}

@keyframes input {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}

/* @keyframes searchKey {
  0% {
    width: 60px;
    right: -28px;
    height: 24px;
    padding-left: 0;
  }
  50% {
    width: 60px;
    right: -28px;
    height: 50px;
    border: 0;
    border-right: 1px solid #131313;
    padding-left: 0.5rem;
  }
  100% {
    width: 460px;
    right: -430px;
    height: 50px;
    border: 1px solid #131313;
    padding-left: 0.5rem;
  }
} */

.expand-menu.active {
  animation: openMenu 200ms linear forwards;
}
.expand-menu.inactive {
  animation: closeMenu 200ms linear forwards;
}

@keyframes closeMenu {
  0% {
    transform: translateX(0%);
    display: block;
  }

  100% {
    transform: translateX(100%);
    display: none;
  }
}
@keyframes openMenu {
  0% {
    transform: translateX(100%);
    display: none;
  }

  100% {
    transform: translateX(0%);
    display: block;
  }
}

@media (max-width: 1024px) {
  .nav-wrapper {
    position: relative;
  }
}

.ais-SearchBox button {
  display: none;
}
.ais-SearchBox input {
  @apply text-blackSand bg-sand;
}

.ais-SearchBox-form {
  width: 100%;
  height: 100%;
}

input::-webkit-search-cancel-button {
  display: none;
}

@media (max-width: 1024px) {
  .searchMobile {
    height: 100%;
    right: 40px;
    transition: all 200ms ease;
  }

  .searchInputMobile {
    width: 0px;
    left: 50%;
    transition: all 200ms ease;
    height: 100%;
    bottom: 0;
  }

  .borderEl {
    top: 72px;
    left: 0%;
  }

  .borderEl::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0px;
    border-bottom: 1px solid #c4bfba;
    bottom: 2px;
    z-index: 100;
    transform: scaleX(0);
    transition: transform 0.2s ease-in;
  }

  .borderEl.showSearch::before {
    transform: scaleX(1);
  }

  .searchInputMobile:focus {
    outline: none;
  }
  .searchInputMobile.showSearch {
    width: 100%;
    left: -1px;
  }

  .searchMobile.showSearch {
    right: 8px;
    padding: 0 8px;
    right: 0px;
  }

  #mobile-menu {
    height: 100% !important;
  }

  #nav-main .shared-button-styles {
    height: initial !important;
  }
  #nav-main .shared-button-styles div {
    height: initial !important;
  }

  #panel-mobile {
    z-index: 100 !important;
    @apply bg-darkSand absolute top-[68px] left-0 w-full;
  }
  #panel-mobile li {
    padding: 8px;
  }
  #panel-mobile li:hover {
    @apply bg-shell;
  }

  #panel-mobile .aa-Panel,
  #panel-mobile .aa-PanelLayout {
    height: unset !important;
  }
}

.aa-Panel .ais-Hits {
  @apply bg-darkSand absolute w-full left-0 top-[39px];
}

.ais-Hits li {
  @apply pt-2 pb-2 pl-2 md:pl-4 md:pb-4 md:pt-4;
}
.ais-Hits li:hover {
  @apply bg-shell;
}

@media (max-width: 768px) {
  .ais-Hits {
    top: 70px;
  }
}

.aa-SubmitButton,
.aa-ClearIcon {
  display: none;
}

#search-main form,
#search-main .aa-InputWrapper {
  height: 100%;
}
#search-main input {
  @apply text-blackSand bg-sand;
}
#search-main input:focus {
  outline: none;
}
#panel-main {
  @apply bg-darkSand absolute w-full left-0 top-[39px];
}
#panel-main li {
  padding: 8px;
}
#panel-main li:hover {
  @apply bg-shell;
}

.ais-SearchBox-input {
  border: 1px solid #131313;
  outline: none;
  width: 630px;
  height: 70px;
  padding-left: 0.5rem;
  @apply font-medium text-blackSand bg-sand md:tracking-btight text-sans-mobile-h3-h4 md:text-sans-h4;
}

.searchIcon {
  top: 26px;
  left: 590px;
}

@media (max-width: 821px) {
  .ais-SearchBox-input {
    width: 100%;
  }

  .searchIcon {
    top: 26px;
    right: 20px;
    left: auto;
  }
}

.search-itinerary-item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.search-hotel-item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.search-blog-item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

@media (max-width: 821px) {
  .search-itinerary-item-content {
    display: none;
  }
  .search-blog-item-content {
    display: none;
  }
}

.dark-overlay::after {
  content: '';
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

.full-width-hero .shared-button-styles:hover {
  @apply bg-sand border-sand;
}

.two-columns-perks-richtext .global-richtext h6 {
  @apply fora-text-h6;
}

.two-columns-perks-richtext .global-richtext p {
  @apply fora-text-body-1;
}

.testimonial-info .global-richtext p {
  @apply pb-0 mb-0 fora-text-h7;
}

.testimonial-info .global-richtext a {
  @apply fora-text-h7;
}

.testimonial-detail,
.testimonial-detail .global-richtext p,
.testimonial-detail .global-richtext a {
  @apply fora-text-caption-1 text-blackSand;
}

.testimonial .global-richtext p {
  @apply fora-text-body-2 text-darkStone;
}

.testimonial.featured .global-richtext p {
  @apply fora-text-body-1 md:fora-text-body-2 md:text-darkStone;
}

.testimonial .global-richtext > p:last-child {
  @apply mb-0;
}

.large-photo-richtext .global-richtext p {
  @apply fora-text-subtitle-1;
}

.large-photo-richtext .global-richtext a {
  @apply fora-text-subtitle-1;
}

.two-columns-richtext .global-richtext p {
  @apply fora-text-body-2;
}

.benefits-rich-text .global-richtext p {
  @apply text-center fora-text-body-1;
}

.benefits-rich-text .global-richtext ul p {
  @apply !pb-0 text-left;
}

.benefits-rich-text .global-richtext h2 {
  @apply pb-4 text-center fora-text-h3;
}

.benefits-rich-text .global-richtext h3 {
  @apply pb-4 text-center fora-text-h6;
}

@media (min-width: 1024px) {
  .perks-content {
    padding-left: 20%;
  }
}

.advisor-embedded-card a {
  text-decoration: none !important;
}

.guide-embedded-card a {
  text-decoration: none !important;
}

.old-phrase {
  animation: rotateOut 0.2s ease-out forwards;
}
.next-phrase {
  animation: rotateIn 0.2s ease-out forwards;
}

@keyframes rotateOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-3rem);
    opacity: 0;
  }
}

@keyframes rotateIn {
  0% {
    transform: translateY(3rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.video-subtitle-copy p {
  @apply text-center lg:max-w-2xl fora-text-body-1;
}

.cta-banner-teaser-copy p {
  @apply fora-text-body-1;
}

.cta-richtext .global-richtext p {
  @apply text-darkStone !pb-[30px];
}

.cta-richtext.small .global-richtext p {
  font-size: 20px;
}

.cta-richtext.full .global-richtext p {
  @apply fora-text-body-1;
}

.cta-richtext .global-richtext ul {
  list-style-type: none;
  margin-left: 0;
  margin-bottom: 20px;
}

.cta-richtext .global-richtext ul:last-of-type {
  margin-bottom: 0px;
}

.cta-richtext .global-richtext ul li {
  background-image: url('https://media.foratravel.com/image/upload/v1715877451/icon-star_kzie38.svg');
  background-position: 0px 2px;
  background-size: 1.2rem 1.2rem;
  background-repeat: no-repeat;
  padding-left: 30px;
}
.cta-richtext .global-richtext ul {
  padding-left: 24px;
}
.cta-richtext .global-richtext h3 {
  @apply mb-4 text-base tracking-2;
}

@media (max-width: 1024px) {
  .picture-section-banner {
    margin-left: calc(-50vw - -50%);
    margin-right: calc(-50vw - -50%);
  }
}

.advisor-card-image-wrapper span {
  height: 100% !important;
}

.no-shrink {
  flex-shrink: 0;
}

.narrow-cards .global-richtext p {
  @apply text-base;
}

@media (min-width: 821px) {
  .narrow-cards > div {
    @apply max-w-[262px];
  }
}

.partner-item {
  height: 200px;
}

@media (max-width: 821px) {
  .featured-hotels ::-webkit-scrollbar {
    display: none;
  }
  .featured-hotels {
    margin-left: calc(-50vw - -50%);
    margin-right: calc(-50vw - -50%);
    padding: 0 calc(+50vw - +50%);
  }
  .hotel-card h3 {
    width: 100%;
  }
}

.partners-learnblock .learn-more-block .global-richtext {
  @apply fora-text-body-1;
}

.partners-learnblock .learn-more-block .global-richtext h1,
.partners-learnblock .learn-more-block .global-richtext h3,
.partners-learnblock .learn-more-block .global-richtext h4 {
  @apply mb-4 uppercase fora-text-eyebrow-1 text-blackSand;
}

#frequently-asked-questions h3 {
  @apply fora-text-h3;
}

.subscription-subcopy p {
  margin-bottom: 0;
  font-size: 14px;
  @apply font-sans text-darkStone;
}

@media (min-width: 60px) and (max-width: 374px) {
  .auto-dots {
    top: 404px;
  }
}

@media (min-width: 375px) and (max-width: 389px) {
  .auto-dots {
    top: 422px;
  }
}
@media (min-width: 390px) and (max-width: 410px) {
  .auto-dots {
    top: 440px;
  }
}
@media (min-width: 410px) and (max-width: 413px) {
  .auto-dots {
    top: 468px;
  }
}
@media (min-width: 414px) and (max-width: 500px) {
  .auto-dots {
    top: 472px;
  }
}
.customDots {
  display: flex !important;
  vertical-align: middle;
  margin: auto;
  margin-top: 20px;
  padding: 0;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  width: 70px;
  text-align: center;
}

.customDots li {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.customDots li.slick-active button:before {
  opacity: 1;
}

.customDots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.customDots li button:before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: black;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.25;
}
.campaign-page .newsletter-form p {
  @apply font-sans text-base;
}

.testimonial-carousel .slick-track {
  display: flex !important;
}

.testimonial-carousel .slick-slide {
  height: inherit !important;
}
.testimonial-carousel .slick-slide > div,
.testimonial-carousel .slick-slide > div > div {
  height: 100% !important;
}

.testimonial-carousel .slick-slide > div > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top-ribbon {
  @apply absolute top-0 w-full h-3 bg-olive;
}

.toggle-wrapper {
  background: rgba(119, 116, 42, 0.2);
  border-radius: 30px;
  overflow: hidden;
  text-align: center;
  position: relative;
  height: 48px;
}

.toggle-wrapper.active-annual .switch {
  @apply left-0;
}

.switch {
  @apply absolute right-0 w-1/2 h-full z-0 rounded-[30px] bg-olive;
}

button.active {
  color: white;
}

.guide-card-image {
  transform: translate(0, -25%) !important;
}

@media screen and (min-width: 821px) {
  .blogpost-card-image {
    transform: translate(0, -25%) !important;
  }
}

.profile-block .global-richtext p {
  @apply text-blackSand fora-text-h3 md:fora-text-h7;
}

.global-banner .global-richtext p,
.global-banner .global-richtext a {
  @apply font-serif text-base tracking-tight !text-white leading-110;
}

.three-icon-image .global-richtext p {
  @apply md:fora-text-body-2 fora-text-body-1;
}

.three-icon-image h2.learn-more-block {
  @apply !pb-0;
}

@media (max-width: 768px) {
  .three-icon-image .header-image:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: black;
    z-index: 10;
    opacity: 0.25;
  }
}

@media (min-width: 821px) {
  video::-webkit-media-controls-play-button {
    width: 35px !important;
    height: 35px !important;
    min-width: unset !important;
    margin-left: 4px;
    border-radius: 50%;
    margin-bottom: 6px;
    @apply bg-shell;
  }

  video::-webkit-media-controls-current-time-display,
  video::-webkit-media-controls-time-remaining-display {
    margin-left: 10px;
    margin-bottom: -5px;
  }
}

.video-container {
  position: relative;
  cursor: pointer;
}

.advisor-review.video-container video {
  height: unset;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  background: url('https://media.foratravel.com/image/upload/v1715877472/PlayButton_joiipk.svg')
    no-repeat center center;
  background-size: cover;
}

.category-card h2 {
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.category-card:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: radial-gradient(transparent 0%, rgba(0, 0, 0, 0.4));
  opacity: 0.6;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOutIn70 {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
}

.headerBlockRichTextSpecialCase div.global-richtext h1 {
  @apply fora-text-h1;
}

.headerBlockRichTextSpecialCase div.global-richtext h1 i {
  @apply fora-text-callout-1;
}

.headerBlockRichTextSpecialCase div.global-richtext h2 {
  @apply fora-text-h2;
}

.headerBlockRichTextSpecialCase div.global-richtext h2 i {
  @apply fora-text-callout-2;
}

.headerBlockRichTextSpecialCase div.global-richtext h3 {
  @apply fora-text-h3;
}

.headerBlockRichTextSpecialCase div.global-richtext h3 i {
  @apply fora-text-callout-3;
}

.headerBlockRichTextSpecialCase div.global-richtext h4 {
  @apply fora-text-h4;
}

.headerBlockRichTextSpecialCase div.global-richtext h4 i {
  @apply fora-text-callout-4;
}

.headerBlockRichTextSpecialCase div.global-richtext h1,
.headerBlockRichTextSpecialCase div.global-richtext h2,
.headerBlockRichTextSpecialCase div.global-richtext h3 {
  @apply mb-6 lg:mb-8;
}

.headerBlockRichTextSpecialCase div.global-richtext p {
  @apply text-darkStone;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue');
  size-adjust: 88%;
}

@font-face {
  font-family: 'Arial';
  src: local('Arial');
  font-stretch: condensed;
}

@font-face {
  font-family: 'Helvetica';
  src: local('Helvetica');
  font-stretch: condensed;
}

.event-richtext h1,
.event-richtext h2,
.event-richtext h3 {
  @apply mb-3 fora-text-h3 md:fora-text-h4;
}

.event-richtext h1 b,
.event-richtext h2 b,
.event-richtext h3 b {
  @apply font-normal;
}

.event-richtext p {
  @apply fora-text-body-1;
}

@media (max-width: 768px) {
  .event-richtext p {
    font-size: 16px;
  }
}

textarea::placeholder,
input::placeholder {
  color: #827363 !important;
  opacity: 1;
}

ul.expertise-list {
  @apply capitalize fora-text-h4 md:fora-text-h7;
}

.advisor-content .profile-block.reduced ul.expertise-list {
  @apply capitalize fora-text-callout-4 md:fora-text-callout-7;
}

.advisor-card-small ul.expertise-list {
  @apply capitalize fora-text-body-italic-3;
}

.question-group-richtext h3 {
  @apply fora-text-h3 text-blackSand lg:text-center;
}
.subscription-card .global-richtext p {
  @apply text-stone fora-text-body-1;
}

.featured-articles-content {
  @apply overflow-hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.advisor-content .profile-block .global-richtext p {
  @apply capitalize fora-text-h4 md:fora-text-h7;
}

.advisor-content .profile-block.reduced .global-richtext p {
  @apply capitalize fora-text-callout-4 md:fora-text-callout-7;
}

.detail-content .global-richtext p {
  @apply mt-6;
}

.detail-content .global-richtext h6 {
  @apply fora-text-h4 md:fora-text-h6;
}

@media (min-width: 768px) {
  .amenities-content {
    column-count: 2;
    @apply gap-x-12;
  }
}
